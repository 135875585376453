<template>
  <sport-hero-banner
    v-if="fields.imageDesktop?.value"
    :fields="fields"
    :params="params"
    :sport-hero-banner-rendering="rendering"
    :active-slide="activeSlide"
  />
  <sport-event-list-main :fields="fields" @filter-change="onChangeSlide" />
</template>

<script setup>
import { defineAsyncComponent, ref } from 'vue';
import SportHeroBanner from './SportHeroBanner/SportHeroBanner.vue';

const SportEventListMain = defineAsyncComponent(() =>
  import(/* webpackChunkName: "SportEventListMain" */ './SportEventListMain.vue')
);

// fix Vue extraneous non-emits event listeners warning
defineEmits(['click']);

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  rendering: {
    type: Object,
  },
  params: {
    type: Object,
  },
});

const activeSlide = ref(props.fields.selectedSportOrTournamentReference?.value || null);

const onChangeSlide = (value) => {
  activeSlide.value = value;
};
</script>

<style lang="scss">
@import './scss/SportEventList.scss';
</style>
